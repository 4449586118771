import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiClient } from '../lib/apiClient';

type RequiredConfigParams<D = any> = Required<Pick<AxiosRequestConfig, 'url' | 'method'>> & Pick<AxiosRequestConfig<D>, 'data'>;
export type PageRequestConfig<D = any> = RequiredConfigParams<D> & Partial<Pick<AxiosRequestConfig, 'headers'>> & { locale?: string, token?: string };

export const apiRequest = async <T = any, K = any>(
    baseParams: PageRequestConfig<T>,
): Promise<K> => {
    try {
        const config = {
            url: baseParams.url,
            method: baseParams.method,
            data: baseParams?.data,
            headers: {
                'X-User-Language': baseParams?.locale || 'en',
                'X-Referrer-Channel': 'site',
                ...(baseParams?.token ? { Authorization: `Bearer ${baseParams.token}` } : {}),
                ...(baseParams.headers || {})
            }
        };
        const response: AxiosResponse<K> = await apiClient.request<any, AxiosResponse<K>, T>(config as AxiosRequestConfig<T>);

        return response.data;
    } catch (err) {
        // const {
        //     response: {
        //         status: responseCode = null,
        //         statusText = '',
        //         data: { message = '', status: errorKey = '' } = {},
        //     } = {},
        //     code: statusCode = '',
        // } = err as AxiosError;
        //
        // throw new Error(
        //     JSON.stringify({
        //         statusCode,
        //         statusMessage: message || statusText,
        //         responseCode,
        //         errorKey,
        //     }),
        // );
    }
};
