import axios, { InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { BASE_URL } from '../constants/common';
// import { getAuthToken } from '../../utils';

export const onSuccessRequestCallback = (config: InternalAxiosRequestConfig) => {
    const newConfig = { ...config };

    // if (!newConfig.headers) {
    //     newConfig.headers = {};
    // }
    //
    // if (IS_DEV && !newConfig.headers.Authorization && getAuthToken()) {
    //     newConfig.headers.Authorization = `Bearer ${getAuthToken()}`;
    // }

    if (!newConfig.headers['Content-Type']) {
        newConfig.headers['Content-Type'] = 'application/json';
    }

    return newConfig;
};

export const onSuccessResponseCallback = (response: AxiosResponse) => response;

export const onErrorResponseCallback = (error: any) => Promise.reject(error);

export const apiClient = axios.create({
    baseURL: BASE_URL
});

apiClient.interceptors.request.use(onSuccessRequestCallback);

apiClient.interceptors.response.use(
    onSuccessResponseCallback,
    onErrorResponseCallback
);
