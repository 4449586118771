export default {
    accountMenu: {
        sessions: 'Próximas y recientes sesiones',
        rooms: 'Habitaciones',
        notifications: 'Notificación',
        support: 'Ayuda y asistencia',
        information: 'Información jurídica',
        settings: 'Ajustes de cuenta',
        messages: 'Mensajes',
        'expert-profile': 'Perfil del experto'
    },
    menu: {
        'bb-client': 'Empieza a crecer con BB',
        'bb-expert': 'Conviértete en un experto en BB',
        home: 'Inicio',
        blog: 'Blog y noticias'
    },
    registration: 'Registro',
    enter: 'Entrar',
    enterAccount: 'Introducir cuenta',
    account: 'Mi cuenta',
    logout: 'Cerrar sesión',
    decline: 'Rechazar',
    send: 'Enviar',
    deleteAcc: 'Eliminar cuenta',
    footer: {
        faq: 'Preguntas frecuentes',
        policy: 'Política de privacidad'
    },
    session: {
        upcoming: 'Próximas sesiones',
        requested: 'Sesiones solicitadas',
        recent: 'Sesiones recientes',
        cancelReason: 'Introduce el motivo por el que has cancelado la sesión',
        reasonPlaceholder: 'Describe el motivo del rechazo',
        decline: 'Rechazar sesión',
        confirm: 'Confirmar sesión',
        join: 'Unirse a la sesión',
        start: 'Iniciar sesión',
        finish: 'Finalizar la sesión',
        comments: 'Comentarios',
        myComments: 'Mis comentarios',
        addComment: 'Añadir nuevo comentario',
        commentPlaceholder: 'Tu comentario',
        clientComments: 'Comentarios del cliente',
        coachComments: 'Comentarios del experto'
    },
    room: {
        upcoming: 'Próximas salas',
        requested: 'Salas solicitadas',
        recent: 'Salas recientes',
        newRoom: 'Nueva sala',
        editRoom: 'Editar la sala',
        date: 'Fecha',
        time: 'Tiempo',
        maxParticipants: 'Máximo de participantes permitidos',
        presenceOfSupervisor: 'Presencia de un supervisor',
        supervisor: 'Supervisor',
        members: 'Miembros',
        participants: 'Participantes',
        roomCreator: 'Creador de salas',
        inviteSupervisor: 'Invitar al supervisor',
        joinSupervisor: 'Unirse como supervisor',
        inviteParticipant: 'Invitar a un participante',
        joinParticipant: 'Unirse como participante',
        rapport: 'Buena relación',
        invite: 'Invitar',
        save: 'Guardar sala',
        rate: 'Valorar',
        tellAboutReason: 'Cuéntanos qué ha pasado',
        rating_raport: 'Buena relación',
        rating_position_and_presence: 'Puesto de coach o presencia de coach',
        rating_balance_and_frustration: 'Equilibrio entre apoyo y frustración',
        rating_agreement: 'Crear un acuerdo de coaching (contrato de sesión)',
        rating_planning_and_goals: 'Planear y establecer los objetivos',
        rating_reality: 'Clarificar la realidad',
        rating_opportunities: 'Nuevas oportunidades encontradas',
        rating_action_plan: 'Se ha diseñado un plan de acción',
        rating_motivation: 'Fuentes de motivación encontradas',
        rating_next_session_stretch: 'Queda un poco para la siguiente sesión',
        rating_relationship: 'Establecer una relación de confianza con el cliente',
        rating_listening: 'Escucha activa y profunda',
        rating_questions: 'Usar preguntas "contundentes"',
        rating_communication: 'Comunicación directa',
        rating_awareness: 'Desarrollar y estimular la conciencia',
        rating_progress: 'Progreso y gestión de la responsabilidad'
    },
    agreementText: 'He leído y acepto las condiciones del Acuerdo de usuario,',
    userAgreement: 'Acuerdo de usuario',
    privacyPolicy: 'Política de privacidad',
    readMore: 'Seguir leyendo',
    photoDesc: 'Añade una foto real, ya que la cara de una persona siempre es más creíble.',
    dayStart: 'Inicio del día',
    topic: 'Tema',
    name: 'Nombre',
    surname: 'Apellido',
    password: 'Contraseña',
    birthday: 'Fecha de nacimiento',
    oldPass: 'Contraseña antigua',
    newPass: 'Nueva contraseña',
    confirmPass: 'Confirmar contraseña',
    forgotPass: 'Se te ha olvidado la contraseña',
    resetPassText: 'Se ha enviado un enlace para restablecer la contraseña a tu correo electrónico',
    or: 'o',
    facebook: 'Cuenta de Facebook',
    apple: 'Cuenta de Apple',
    google: 'Cuenta de Google',
    becomeExpert: '',
    insertInfo: 'Introduce tu información personal para comenzar tu viaje como experto en BBuddy',
    changeUserData: 'Tus datos pueden añadirse o modificarse en cualquier momento',
    price: 'Precio',
    duration: 'Duración',
    search: 'Buscar',
    searchExpert: 'Buscar un experto',
    sort: 'Ordenar',
    sortPriceAsc: 'Por precio ascendente',
    sortPriceDesc: 'Por precio descendiente',
    details: 'Detalles',
    sessionLang: 'Idioma de la sesión',
    direction: 'Dirección',
    fromTo: 'de $ a $',
    apply: 'Solicitar',
    save: 'Guardar',
    edit: 'Editar',
    changePass: 'Cambiar contraseña',
    resetPass: 'Restablecer contraseña',
    getStarted: 'Empieza',
    delete: 'Eliminar',
    today: 'Hoy',
    back: 'Volver',
    backToExperts: 'Volver a la lista de expertos',
    courseInfo: 'Información del curso',
    expertBackground: 'Antecedentes del experto',
    profCertification: 'Certificación profesional',
    practiceHours: 'Horas de práctica',
    supervisionCount: 'Supervisiones anuales',
    outOf: 'de',
    schedule: 'Horario',
    successfulCase: 'Casos de éxito de la práctica',
    signUp: 'Regístrate ahora',
    noData: 'Sin datos',
    notFound: 'No encontrado',
    skillsInfo: 'Información',
    trainings: 'Formación',
    seminars: 'Seminarios',
    courses: 'Cursos',
    mba: 'Información sobre máster en ADE (MBA)',
    aboutCoach: 'Acerca del experto',
    education: 'Educación',
    coaching: 'Perfil del experto',
    experiences: 'Experiencia práctica',
    payInfo: 'Información de pago',
    sessionDuration: 'Duración de la sesión',
    experienceHours: 'Total de horas de experiencia práctica',
    topics: 'Temas',
    selectTopic: 'Seleccione el tema',
    title: 'Título',
    description: 'Descripción',
    sessionCost: 'Coste de la sesión en euros',
    yourTimezone: 'Tu zona horaria',
    workTime: 'Tiempo de trabajo',
    startAt: 'Empieza a las',
    finishAt: 'Termina a las',
    day: 'Día',
    addWorkingHours: 'Añadir horas de trabajo',
    specialisation: 'Especialización',
    selectSpecialisation: 'Selecciona tu especialización para continuar',
    fillWeeklySchedule: 'Rellena tu agenda semanal',
    beneficiaryName: 'Nombre del beneficiario',
    bicOrSwift: 'Código BIC/Swift',
    association: 'Asociación',
    level: 'Nivel',
    addDiploma: 'Añadir diploma',
    university: 'Institución',
    sunday: 'D',
    monday: 'L',
    tuesday: 'M',
    wednesday: 'X',
    thursday: 'J',
    friday: 'V',
    saturday: 'S',
    addNew: 'Añadir nuevo',
    mExperiences: 'Experiencia de dirección',
    pay: 'Pago',
    sessionWishes: 'Escribe tus deseos sobre la sesión',
    successPayment: 'Pago Exitoso',
    errorPayment: 'Error de Pago',
    errors: {
        invalidEmail: 'La dirección de correo electrónico no es válida',
        emptyEmail: 'Introduce tu correo electrónico',
        emptyPass: 'Introduce tu contraseña',
        confirmPass: 'Confirma tu contraseña',
        notMatchPass: 'Las nuevas contraseñas que has introducido no coinciden',
        emptyCancelReason: 'Introduce el motivo',
        approvingSession: 'Error al aprobar la sesión',
        finishingSession: 'Error al finalizar la sesión',
        emptyComment: 'Introduce tu comentario',
    },
}
