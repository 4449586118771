import { Locale } from '../types/locale';
import en from './en';
import ru from './ru';
import fr from './fr';
import de from './de';
import it from './it';
import es from './es';

const MESSAGES = {
    [Locale.en]: en,
    [Locale.ru]: ru,
    [Locale.de]: de,
    [Locale.fr]: fr,
    [Locale.it]: it,
    [Locale.es]: es
};

const getValue = (keys: string[], dictionary: any) => {
    const keysClone = [...keys];
    const firstElement = keysClone.shift();

    if (firstElement && dictionary[firstElement]) {
        return typeof dictionary[firstElement] === 'string' ? dictionary[firstElement] : getValue(keysClone, dictionary[firstElement]);
    } else {
        return '';
    }
};

export const i18nText = (key: string, locale?: string): string => getValue(key.split('.'), MESSAGES[locale || Locale.en]);
